import React from 'react';
import Helmet from 'react-helmet';

import PageTemplateContainer from 'src/containers/common/PageTemplateContainer';
import Tags from 'src/components/tags/Tags';

const TagsPage = ({
  data: {
    allMarkdownRemark: { group },
    site: {
      siteMetadata: { title },
    },
  },
}) => {
  return (
    <PageTemplateContainer>
      <Tags helmet={<Helmet title={`Tags | ${title}`} />} tags={group} />
    </PageTemplateContainer>
  );
};

export default TagsPage;

export const tagPageQuery = graphql`
  query TagsQuery {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(limit: 1000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`;
