import React from 'react';
import classNames from 'classnames/bind';
import { kebabCase } from 'lodash';
import { Link } from 'gatsby';

import PostWrapper from 'src/components/post/PostWrapper';

import styles from './Tags.module.scss';

const cx = classNames.bind(styles);

const Tags = ({ helmet, tags }) => {
  const tagList = tags.map(tag => (
    <Link
      key={kebabCase(tag.fieldValue)}
      to={`/tags/${kebabCase(tag.fieldValue)}/`}
    >
      {tag.fieldValue} ({tag.totalCount})
    </Link>
  ));
  return (
    <div>
      <PostWrapper>
        <section className={cx('tags')}>
          {helmet || ''}
          <h1>Tags</h1>

          <div className={cx('list')}>{tagList}</div>
        </section>
      </PostWrapper>
    </div>
  );
};

export default Tags;
